import styles from "./styles.module.css";
import cn from "classnames";

const Heading = ({ children, size = "medium", color = "black" }) => {
  return (
    <h2
      className={cn(styles.heading, {
        [styles.headingWhite]: color === "white",
        [styles.headingSmall]: size === "small",
        [styles.headingMediumSmall]: size === "mediumSmall",
        [styles.headingBig]: size === "big",
      })}
    >
      {children}
    </h2>
  );
};

export default Heading;
