import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Container from "../Container";
import Heading from "../Heading";
import Label from "../Label";
import ArrowLink from "../ArrowLink";
import ArrowIcon from "../Icons/Arrow";
import styles from "./styles.module.css";
import cn from "classnames";
import MyImage from "../MyImage";

const ResizePlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on("created", () => {
    observer.observe(slider.container);
  });
  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
  });
};

const NewsItem = ({ isActive, node }) => {
  const category = node.categories.nodes.length
    ? node.categories.nodes[0].name
    : null;

  const author =
    !!node.commonFields.authors && node.commonFields.authors.length
      ? node.commonFields.authors[0].name
      : "Sustainable Capital Advisors";

  const participant =
    !!node.podcastFields.participants && node.podcastFields.participants.length
      ? node.podcastFields.participants[0].name
      : "Sustainable Capital Advisors";

  const displayAuthor = category === "Podcast" ? participant : author;

  return (
    <div
      className={cn("keen-slider__slide", styles.newsItem, {
        [styles.activeNewsItem]: isActive,
      })}
    >
      <Link href={`/resources/${node.slug}`} passHref>
        <a>
          <div
            className={styles.newsImage}
            style={{
              width: true ? "408px" : "300px",
              height: isActive ? "320px" : "245px",
            }}
          >
            {!!node.categories.nodes.length && (
              <div className={styles.labelWrapper}>
                <Label>{category}</Label>
              </div>
            )}
            {node.featuredImage && (
              <MyImage
                objectFit="cover"
                layout="fill"
                src={node.featuredImage.node.sourceUrl}
                alt={
                  node.featuredImage.node?.altText ||
                  node.featuredImage.node?.title
                }
              />
            )}
          </div>
          <div className={styles.newsTop}>
            <span className={styles.newsDate}>
              {node.date
                ? new Date(node.date).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : null}
            </span>
            <span className={styles.newsCategory}>
              {node.commonFields.label}
            </span>
          </div>
          <div
            className={styles.newsExcerpt}
            dangerouslySetInnerHTML={{ __html: node.excerpt }}
          ></div>
          <p className={styles.newsAuthor}>{displayAuthor}</p>
          <ArrowLink onClick={() => {}}>Learn more</ArrowLink>
        </a>
      </Link>
    </div>
  );
};

const News = ({ title, text, posts, background }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [refCallback, slider, sliderNode] = useKeenSlider(
    {
      loop: false,
      mode: "snap",
      rtl: false,
      slides: { perView: "auto", spacing: 24 },
      slideChanged(e) {
        setCurrentSlide(e.track.details.abs);
      },
    },
    [ResizePlugin]
  );
  return (
    <div className={cn(styles.news, { [styles.newsWhite]: background })}>
      <Container>
        <div className={styles.row}>
          <div className={styles.content}>
            <Heading>In the news</Heading>
            <p className={styles.text}>
              Learn more about recent blog posts, news features, and speaking
              engagements.
            </p>
            <ArrowLink href="/resources">All news</ArrowLink>
            <div className={styles.navigation}>
              <button
                aria-label="prev"
                onClick={() => {
                  slider.current.prev();
                }}
                className={styles.prev}
              >
                <ArrowIcon fill="var(--green)" />
              </button>
              <button
                aria-label="next"
                onClick={() => {
                  slider.current.next();
                }}
                className={styles.next}
              >
                <ArrowIcon fill="var(--green)" />
              </button>
            </div>
          </div>
          <div className={styles.sliderWrapper}>
            <div ref={refCallback} className={cn("keen-slider", styles.slider)}>
              {[...posts, ...posts, ...posts, ...posts, ...posts].map(
                (node, index) => (
                  <NewsItem
                    node={node}
                    isActive={index === currentSlide}
                    key={index}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default News;
