import Link from "next/link";
import styles from "./styles.module.css";
import ArrowIcon from "../Icons/Arrow";

const ArrowLink = ({ href = "#", onClick, children }) => {
  if (onClick) {
    return (
      <button onClick={onClick} className={styles.link}>
        {children}
        <ArrowIcon fill="var(--green)" />
      </button>
    );
  }
  return (
    <Link href={href || "#"} passHref>
      <a className={styles.link}>
        {children}
        <ArrowIcon fill="var(--green)" />
      </a>
    </Link>
  );
};

export default ArrowLink;
