import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Arrow from "../Icons";
import Image from "next/image";
import Link from "next/link";
import MyImage from "../MyImage";

const Cta = ({ title = "", subtitle = "", button, icon }) => {
  const pageHref = button ? button.url : null;
  const pageTitle = button ? button.title : null;
  const iconUrl = icon ? icon?.node?.sourceUrl : null;
  const iconAlt = icon ? icon?.node?.altText || icon?.title : null;
  return (
    <div className={styles.cta}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            {iconUrl && (
              <MyImage src={iconUrl} width="48" height="48" alt={iconAlt} />
            )}
          </div>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle}>{subtitle}</p>
          <div className={styles.buttonWrapper}>
            <Button type="white" href={pageHref} withArrow>
              {pageTitle}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Cta;
