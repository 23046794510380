import Link from "next/link";
import Image from "next/image";
import styles from "./styles.module.css";
import { Arrow } from "../Icons";
import cn from "classnames";

const Label = ({ children, type }) => {
  return (
    <div
      className={cn(styles.label, {
        [styles.transparentWhite]: type === "transparentWhite",
        [styles.transparentBlack]: type === "transparentBlack",
      })}
    >
      {children}
    </div>
  );
};

export default Label;
