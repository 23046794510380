import Container from "../Container";
import Button from "../Button";
import { Twitter, LinkedIn } from "../Icons";
import styles from "./styles.module.css";
import Image from "next/image";
import MyImage from "../MyImage";

export default function Footer({
  data: {
    text,
    twitter,
    linkedin,
    copyrights,
    topLeftHeading = "",
    topLeftLinks = [],
    topRightHeading = "",
    topRightLinks = [],
    bottomRightLinks,
    button,
  },
}) {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.top}>
          <div className={styles.about}>
            <MyImage src="/logo.png" width="148" height="64" alt="logo" />
            {text && <p className={styles.aboutText}>{text}</p>}
          </div>
          <div className={styles.topLinksWrapper}>
            <h2 className={styles.topLinksHeading}>{topLeftHeading}</h2>
            <ul className={styles.topLinks}>
              {topLeftLinks &&
                topLeftLinks.map(
                  (item) =>
                    item.link && (
                      <li key={item.link.url}>
                        <a href={item.link.url}>{item.link.title}</a>
                      </li>
                    )
                )}
            </ul>
          </div>
          <div className={styles.topLinksWrapper}>
            <h2 className={styles.topLinksHeading}>{topRightHeading}</h2>
            <ul className={styles.topLinks}>
              {topRightLinks &&
                topRightLinks.map(
                  (item) =>
                    item.link && (
                      <li key={item.link.url}>
                        <a href={item.link.url}>{item.link.title}</a>
                      </li>
                    )
                )}
            </ul>
          </div>
          <div className={styles.buttonWrapper}>
            <Button type="yellow" href={button.url}>
              {button.title}
            </Button>
          </div>
        </div>
        <div className={styles.bottom}>
          <ul className={styles.social}>
            <li className={styles.socialIcon}>
              <a href={linkedin} aria-label="LinkedIn">
                <LinkedIn />
              </a>
            </li>
            <li className={styles.socialIcon}>
              <a href={twitter} aria-label="Twitter">
                <Twitter />
              </a>
            </li>
          </ul>
          <p className={styles.copyright}>{copyrights}</p>
          <ul className={styles.bottomLinks}>
            {bottomRightLinks.map((item) => (
              <li className={styles.bottomLink} key={item.link.url}>
                <a href={item.link.url}>{item.link.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  );
}
